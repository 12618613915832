  // Import the functions you need from the SDKs you need
  // import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-app.js";
  import { initializeApp } from 'firebase/app';
  //import { getFirestore, doc, setDoc } from 'firebase/firestore';
  import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'; 
  import { getDatabase, ref, set, onValue, onDisconnect} from "firebase/database";
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBb_SFVfJrgGSeVuc1b82ffo0sIjOV4y3U",
    authDomain: "throw-guys-rps.firebaseapp.com",
    projectId: "throw-guys-rps",
    storageBucket: "throw-guys-rps.appspot.com",
    messagingSenderId: "140481760473",
    appId: "1:140481760473:web:be11636f382bafae5ec2c8",
	databaseURL: "https://throw-guys-rps-default-rtdb.firebaseio.com"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  //const db = getFirestore(app);
  const auth = getAuth(app);
  const db = getDatabase();
  // export {db, auth, onAuthStateChanged, signInAnonymously, doc, setDoc};
  export {db, auth, onAuthStateChanged, signInAnonymously, ref, set, onValue, onDisconnect};