import Vue from 'vue'
import Router from 'vue-router'
// import HomePage from '../App.vue'
import GameView from '@/views/GameView.vue'
import SignIn from '@/views/SignIn.vue'
import PlayGame from '@/components/PlayGame.vue'

Vue.use(Router)

export default new Router({
	routes: [
		{ path: '/', component: GameView },
		{ path: '/signin', component: SignIn },
		{ path: '/play', component: PlayGame },
	]
})