<template>
  <div class="flex flex-row space-x-20">
    <button type="button" class="border p-8 rounded-full border-[28px] bg-white border-[#00B4ED]" v-on:click="rockChosen()" :class="{'active': chosenPlay === 'rock'}">
      <div class="w-[75px]">
        <img class="img mx-auto" src="@/assets/rock.png"/>
      </div>
    </button>
    <button type="button" class="border p-8 rounded-full border-[28px] bg-white border-[#00B4ED]" v-on:click="paperChosen()" :class="{'active': chosenPlay === 'paper'}">
      <div class="w-[75px]">
        <img class="img mx-auto" src="@/assets/paper.png"/>
      </div>
    </button>
    <button type="button" class="border p-8 rounded-full border-[28px] bg-white border-[#00B4ED]" v-on:click="scissorsChosen()" :class="{'active': chosenPlay === 'scissors'}">
      <div class="w-[75px]">
        <img class="img mx-auto" src="@/assets/scissors.png"/>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chosen: ''
    }
  },
  computed: {
    chosenPlay() {
      return this.chosen;
    }
  },
  methods: {
    rockChosen() {
      this.chosen = 'rock';
      this.$emit('getChoice', 'rock')
    },
    paperChosen() {
      this.chosen = 'paper';
      this.$emit('getChoice', 'paper')
    },
    scissorsChosen() {
      this.chosen = 'scissors';
      this.$emit('getChoice', 'scissors')
    },
  }
};
</script>

<style scoped>
button.active {
  border-color: #0056DA;
}

.container {
  display: grid;
  
}
.img {
  height:75px;
  width: auto;
}
</style>