<template>
	<div class="sm:mx-auto sm:w-full sm:max-w-md rounded-lg grid h-screen place-items-center">
		<div class="bg-white rounded-lg p-8">
			<img class="mb-4" src="@/assets/throw-logo.png"/>
			<form class="flex flex-col content-center space-y-4" @submit.prevent="onSignInSubmit(newPlayerName)" v-if="!user">
				<div>
					<label>Hey Guy, what's your name?</label>
					<div class="mt-1">
						<input class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" type="text" v-model="newPlayerName">
					</div>
				</div>
				<!-- </label> -->
				<div v-if="signInError">
					<h3>Enter a valid name</h3>
				</div>
				<div>
					<button class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4  font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Sign In</button>
				</div>
			</form>
		</div>
		<!-- <div class="header-container" v-else>
			<h1>Welcome {{player.name}}!</h1>
		</div> -->
	</div>
</template>
<script>
// import { auth, signInAnonymously, onAuthStateChanged, db, doc, setDoc } from "@/firebaseInit"
import { auth, signInAnonymously, onAuthStateChanged, db, set, ref } from "@/firebaseInit"
import { mutations, store } from '@/store'

export default {
	name: 'App',
	components: {
	},
	data() {
		return {
			user: null,
			player: null,
			newPlayerName: "Guy",
			playerRef: null,
			signInError: null,
		};
	},
	computed: {
		player1() {
			return store.player1;
		},
		player2() {
			return store.player2;
		}
	},
	methods: {
		playerAuth(playerName) {
			signInAnonymously(auth).then(() => {
				console.log("anon sign in");

				onAuthStateChanged(auth, (user) => {
					console.log(user)
					this.user = user;
					if (user) {
						console.log(this.player1.name)
						if (!this.player1.name) {
							console.log("in if statement")
							mutations.setPlayer1Name(playerName);
							mutations.setPlayer1Uid(user.uid);
							this.player = this.player1;
						}
						// else {
						// 	mutations.setPlayer2Name(playerName);
						// 	mutations.setPlayer2Uid(user.uid);
						// 	this.player = this.player2;
						// }
						console.log(this.player1.uid)
					}

					this.playerRef = ref(db, 'players/' + user.uid);
					set(this.playerRef, this.player);
					}
				)
			})
		},
		onSignInSubmit(playerName) {
			if (!playerName) {
				this.signInError = "Invalid Name";
			}
			else {
				this.playerAuth(playerName);
				this.signInError = null;
				//mutations.setPlayer1Name(playerName);
				this.$router.push('/');
			}
		},
	},
	created() {

	}
}
</script>