import Vue from "vue";

export const store = Vue.observable({
  player1: { name: null, choice: "", score: 0, uid: null },
  player2: { name: null, choice: "", score: 0, uid: null }
});

export const mutations = {
    setPlayer1(player) {
        store.player1 = player;
    },
    setPlayer2(player) {
        store.player2 = player;
    },
    setPlayer1Choice(choice) {
        store.player1.choice = choice;
    },
    setPlayer2Choice(choice) {
        store.player2.choice = choice;
    },
    setPlayer1Name(name) {
        store.player1.name = name;
    },
    setPlayer2Name(name) {
        store.player2.name = name;
    },
	setPlayer1Uid(uid) {
        store.player1.uid = uid;
    },
    setPlayer2Uid(uid) {
        store.player2.uid = uid;
    }
};