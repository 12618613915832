<template>
  <div id="app" class="h-screen">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {
    },
    created() {
      this.$router.push('/signin');
    }
  }
</script>

<style>
  #app {
    background-image: url(@/assets/fg-bg.svg);
    background-size: cover;
  }
</style>