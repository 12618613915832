<template>
	<div class="grid h-screen place-items-center">
	<div class="h-[700px] text-center">
		<h1 v-if="winner" class="text-8xl blink_me mb-10 text-[#FF00F2]">{{winner.name}}!</h1>
		<h1 v-else class="text-8xl blink_me mb-10 text-[#FF00F2]">THROW!</h1>
		<div class="mx-auto max-w-4xl h-[500px] w-[900px] grid place-items-center bg-white rounded-lg  relative">
			<div class="flex flex-row space-x-20 ">
				<div>
					<div class="p-8 rounded-full border-[28px] bg-white border-[#FF00F2]">
						<div class="h-[75px]">
							<img v-if="player1.choice ===''" class="w-[75px] mx-auto" src="@/assets/arrived.png"/>
							<img v-else-if="player1.choice ==='rock'" class="w-[75px] mx-auto" src="@/assets/rock.png"/>
							<img v-else-if="player1.choice ==='paper'" class="w-[75px] mx-auto" src="@/assets/paper.png"/>
							<img v-else class="w-[75px] mx-auto" src="@/assets/scissors.png"/>
						</div>
					</div>
					<h1 class="text-5xl text-[#0056DA] mt-6 uppercase">{{player1.name}} Guy</h1>
				</div>
				<div>
					<div class="p-8 rounded-full border-[28px] bg-white border-[#FF00F2]">
						<div class="h-[75px]">
							<img v-if="player2.choice ===''" class="w-[75px] mx-auto" src="@/assets/arrived.png"/>
							<img v-else-if="player2.choice && !player.choice" class="w-[75px] mx-auto" src="@/assets/chosen.png"/>
							<img v-else-if="player2.choice ==='rock'" class="w-[75px] mx-auto" src="@/assets/rock.png"/>
							<img v-else-if="player2.choice ==='paper'" class="w-[75px] mx-auto" src="@/assets/paper.png"/>
							<img v-else class="w-[75px] mx-auto" src="@/assets/scissors.png"/>
						</div>
					</div>
					<h1 class="text-5xl text-[#0056DA] mt-6 uppercase">
						<span v-if="player2.name">{{player2.name}}</span>
						<span v-else>Other</span>
						Guy</h1>
				</div>
			</div>
			<!-- ScoreBoard -->
			<!-- <div class="scoreboard">
				<div class="scorecontainer">
					<h2 class="score_text">ScoreBoard</h2>
					<div class="float-child">
						<div class="player1">
							<h3>You</h3>
							<h3 class="score">{{ player1_score }}</h3>
						</div>
					</div>
					<div class="float-child">
						<div class="oponent">
							<h3>PlayerTwo</h3>
							<h3 class="score">{{ player2_score }}</h3>
						</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="instructions">
				<h3>Press on your chosen image and then press PLAYGAME!</h3>
			</div> -->
			<!-- Game -->
			<!-- <div class=""> -->
				<PlayerOne class="absolute bottom-0 mb-[-100px]" v-on:getChoice="updatePlayerOneChoice($event)" v-bind:choice="player1.choice" />
				<!-- <PlayerTwo class="absolute bottom-0 mb-[-100px]" v-on:getChoice="updatePlayerTwoChoice($event)" v-bind:choice="player2.choice" /> -->
				<!-- <h2 style="color:white">You chose: {{ player1.choice }}</h2> -->
			<!-- </div> -->
			<!-- <div class="float-child2">
				<PlayerTwo @click="play" v-on:getChoice="updatePlayerTwoChoice($event)" v-bind:choice="player2.choice" />

				<button v-if="player1.choice && player2.choice" type="button" @click="play">Play</button>

				<h2 style="color:white">PlayerTwos choice: {{ player2.choice }}</h2>
			</div> -->
			<!-- <div v-if="player1.choice && player2.choice"></div> -->
			<h1 class="winner" v-if="player1.choice && player2.choice && !winner">
				It's a Tie!
			</h1>
			<h1 class="winner" v-if="winner && winner.name">
				{{ winner.name }}
			</h1>
		</div>
		</div>
	</div>
</template>
<script>
import PlayerOne from '@/components/PlayerOne.vue'
// import PlayerTwo from '@/components/PlayerTwo.vue'
import { selectWinner } from '@/rps.js'
//import { db, doc, setDoc } from "@/firebaseInit"
import { db, set, ref, onValue} from "@/firebaseInit"
import { mutations, store } from '@/store'

export default {
	name: 'GameView',
	components: {
		PlayerOne,
		// PlayerTwo,
	},
	data() {
		return {
			user: null,
			player: null,
			newPlayerName: "Guy",
			playerRef: null,
			winner: "",
			signInError: null,
			newPlayerRef: ref(db, 'players'),
			players: null,
			gameReady: false
		};
	},
	computed: {
		player1() {
			return store.player1;
		},
		player2() {
			return store.player2;
		}
	},
	methods: {
		playerCheck() {
			if (this.addedPlayer.length==2) {
				if (this.addedPlayer[0].uid == this.player1.uid) {
					mutations.setPlayer2 = this.addedPlayer[1];
				}
				else {
					mutations.setPlayer2 == this.addedPlayer[0];
				}
				console.log("player 1:{{this.player1}}",this.player2)
			}
		},
		updatePlayerOneChoice(choice) {
			mutations.setPlayer1Choice(choice);
			console.log(this.player1);
			this.updateDB(this.player1);
			console.log(this.player1)
		},
		updatePlayerTwoChoice(choice) {
			mutations.setPlayer2Choice(choice);
		},
		ImgSrc() {
			if (this.player2.choice == "rock") {
				return "rock.jpeg"
			}
			else if (this.player2.choice == "paper") {
				return "paper.jpeg"
			}
			else {
				return "scissors.jpeg"
			}
		},
		updateDB(player) {
			this.playerRef = ref(db, 'players/' + player.uid);
			set(this.playerRef, player);
		},
		play() {
			// const {player1, player2 } = this;
			this.winner = selectWinner(this.player1, this.player2);
			if (this.winner) {
				if (this.winner.uid == this.player1.uid) {
					this.player1.score = this.player1.score + 1;
				}
				if (this.winner.uid == this.player2.uid) {
					this.player2.score = this.player2.score + 1;
				}
				this.updateDB(this.winner);
			}

			// if (player1_choice === player2_choice) {
			//   this.winner = "It is a tie!";
			// } else if (
			//     (player2_choice === "scissors" && player1_choice === "paper") ||
			//      (player2_choice === "paper" && player1_choice === "rock") ||
			//      (player2_choice === "rock" && player1_choice === "scissors")
			//   ) {
			//       this.player2_score++;
			//       this.winner = "PlayerTwo won :(";
			// } else if (player1_choice === "") {
			//   alert("You have to select your choise!")
			// }
			// else {
			//   this.player1_score++;
			//   this.winner = "You won!";
			//     }
		},
		playSound() {
			// console.log('playSound')
			const audio = new Audio(require('@/static/throw-jingle.mp3'))
			audio.play();
		}
	},
	watch: {
		winner: function (val) {
			this.winner = val;
		},
		gameReady: function (val) {
			if (val) {
				console.log('play!')
				this.playSound();
				setTimeout(() => {
					this.play();
				}, 13000);
			}
		}
	},
	created() {
		onValue(ref(db, 'players'), (snapshot) => { 
			const addedPlayer = snapshot.val();
			console.log(addedPlayer);
			console.log(this.player1.uid);
			const playersArray = Object.entries(addedPlayer)
			console.log(playersArray)
			console.log(playersArray[1][1])
			const playerLength=Object.keys(addedPlayer).length
			if (playerLength==2 && !this.player2.uid) {
				if (playersArray[0][0] == this.player1.uid) {
					mutations.setPlayer2(playersArray[1][1]);
				}
				else {
					mutations.setPlayer2(playersArray[0][1]);
				}
			this.gameReady = true;
			console.log(this.player1,this.player2)
			}
		})
	{
		onValue(ref(db, 'players/'+this.player2.uid), (snapshot) => {
			const addedChoice = snapshot.val();
			if (this.player2.choice) {
				//mutations.setPlayer2(addedChoice)
				console.log("choice here")
				console.log(addedChoice);
			}
			
})
	}
}}

</script>

<style>
*,
/* .body {
	background-color: #292c34;
	overflow: hidden;
} */

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Asap, sans-serif;
	font-weight: 700;
}


</style>