class Referee {
  rules = {};
  constructor() {}

  validate = (choice) => choice in this.rules;
  getChoices = () => Object.keys(this.rules);
  learn = (winner, loser) => {
    if (!this.validate(winner)) {
      this.rules[winner] = {};
    }
    this.rules[winner][loser] = 1;
  };

  judge(user1, user2) {
    return user1.choice === user2.choice
      ? null
      : this.rules[user1.choice][user2.choice] === 1
      ? user1
      : user2;
  }
}

export const selectWinner = (user1, user2) => {
  console.log("selecting winner")
  const referee = new Referee();
  referee.learn("rock", "scissors");
  referee.learn("paper", "rock");
  referee.learn("scissors", "paper");

  return referee.judge(user1, user2);
};

const user1 = {};
const user2 = {};
user1.name = "Jeff";
user2.name = "Broc";
user1.choice = "rock";
user2.choice = "scissors";
console.log(selectWinner(user1,user2));